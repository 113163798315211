<template>
  <ml-container>
    <ml-grid>
      <div class="tw-col-span-5">
        <form ref="form" novalidate="false" @submit.prevent="onSubmit" @change="validateForm()" method="POST">
          <div class="tw-flex tw-items-center tw-justify-between">
            <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-4">
              {{ item.id ? 'Edit Fees':'Create Fees' }}
            </h1>
            <router-link :to="{ name: 'vendor.offers' }" class="tw-text-red tw-text-sm tw-flex tw-items-center tw-mb-4">
              <svg class="tw-mr-3" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 1L1 5L5 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Back
            </router-link>
          </div>

          <div>
              <div class="tw-mt-8">
                <ml-label required for="categories">{{$t('vendor.offer.categories')}}</ml-label>
                <div class="tw-mt-2">
               
                  <v-select
                      v-model="item.categories"
                      :items="categories"
                      :rules="[required]"
                      item-text="name"
                      item-value="id"
                      
                      chips
                      dense
                     
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip color="white">
                            <span v-for="(parent,index) in item.parents" :key="index">
                                  {{parent.name}} |
                            </span>                        
                            <span>{{ item.name }}</span>
                          </v-chip>
                    </template>    
                      <template v-slot:item="{item,attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <div class="col">
                                  <span v-for="(parent,index) in item.parents" :key="index">
                                  {{parent.name}} |
                                  </span>
                                  
                                  <span :style="active ? 'font-weight:bold':''">{{ item.name }}</span> 
                                </div>
                                <div class="col" align="right">
                                   <span style="color:gray">{{item.type.name}}</span>
                                </div>                                
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                                                        
                  </v-select>
                  <ml-error-msg :msg="errors['categories']" />
                </div>
              </div>

            <div>
              <ml-label required for="name">Title</ml-label>
              <div class="tw-mt-2">
                <ml-input-text v-model="item.name" id="name" name="name" required/>
                <ml-error-msg :msg="errors['name']" />
              </div>
            </div>
            <div class="tw-mt-6">
              <ml-label required for="description">Description</ml-label>
              <div class="tw-mt-2">
                <ml-textarea v-model="item.description" id="description" name="description" required/>
                <ml-error-msg :msg="errors['description']" />
              </div>
            </div>
            <ml-grid class="tw-mt-6">
              <div class="tw-col-span-4">
                <ml-label required for="price">Price</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text type="number" min="0" v-model="item.price" id="price" name="price" required/>
                  <ml-error-msg :msg="errors['price']" />
                </div>
              </div>
            </ml-grid>
          </div>

          <div class="tw-mt-6">
            <router-link :to="{ name: 'vendor.offers' }"
                         class="tw-mt-6 tw-mr-6 tw-px-8 tw-py-3 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              Cancel
            </router-link>
            <button type="submit"
                    :disabled="!valid || loading"
                    class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
              {{ buttonText }}
            </button>
          </div>
        </form>
      </div>
    </ml-grid>
  </ml-container>
</template>

<script>

import MlContainer from "@/components/layout/MlContainer";
import MlGrid from "@/components/layout/MlGrid";
import MlLabel from "@/components/layout/form/MlLabel";
import MlInputText from "@/components/layout/form/MlInputText";
import MlTextarea from "@/components/layout/form/MlTextarea";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import i18n from '@/i18n'


export default {
  components: {MlErrorMsg, MlTextarea, MlGrid, MlContainer, MlLabel, MlInputText},
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  metaInfo() {
    return {
      title: i18n.t('feesmng'),
    }
  },
  data() {
    return {
      item: {},
      selectedFile: null,
      loading: false,
      file: null,
      rules: {
        required: value => !!value || 'Required.',
        max: v => v <= 13 || 'Max 13 digits'
      },
      valid: true,
      lazy: true,
      errored: false,
      categories: [],
      errors: []
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getItem();  
    }
    this.getCategories();
  },
  computed: {
    buttonText() {
      return this.item.id ? 'Update' : 'Create'
    }
  },
  methods: {
    mapData() {
      this.item.categories = this.item.categories[0].id;
    },
    required(value) {
      if (value instanceof Array && value.length === 0) {
        return 'Required.';
      }
      return !!value || 'Required.';
    },
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

        if (!this.item.name) {
          label = "Name required.";
          this.errors.push(label);
          this.errors["name"] = label;
        }

        if (!this.item.description) {
          label = "Description required.";
          this.errors.push(label);
          this.errors["description"] = label;
        }

        if (!this.item.price) {
          label = "Price required.";
          this.errors.push(label);
          this.errors["price"] = label;
        }      

        if (this.item.price) {
          if (this.item.price < 0.5) {
            label = "The minimum amount is $0.50";
            this.errors.push(label);
            this.errors["price"] = label;
          }
        }

        if (this.item.categories instanceof Array && this.item.categories.length === 0 || !this.item.categories) {
          label = "Category required.";
          this.errors.push(label);
          this.errors["categories"] = label;
        }  

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },   
    onSubmit(){
      if(this.validateForm()) this.dataEdit();
    },    
    dataEdit() {
      this.loading = true;
      if (this.item.id) {
        this.updateItem();
      } else {
        this.addItem();
      }
    },
    getCategories() {
      let uri = '/api/vendor/categories?filter[tax_code_null]=false&filter[type_name_in]=Fees';
      this.$http.get(uri).then((response) => {
        this.categories = response.data.data
      });
    },    
    async addItem() {

      //await this.$refs.form.validate();
      if(!this.validateForm()){
        return;
      }
      if (!this.valid) return;
      //console.log("addItem");
      this.loading = true;
      let uri = '/api/vendor/fees';
      this.$http.post(uri, this.item)
          .then((response) => {
            this.item = response.data.data
            this.mapData()
            this.$toast.success(response.data.message, "Success")
          }).catch(error => {
        console.log('Error add data: ' + error)
        this.errored = true
      })
          .finally(() =>
              this.$router.push({name: 'vendor.offers', query: { page: 'Fees' }})
          );
    },
    async updateItem() {

      //await this.$refs.form.validate();
      if(!this.validateForm()){
        return;
      }
      if (!this.valid) return;

      //console.log("updateItem");
      this.loading = true;
      let uri = '/api/vendor/fees/' + this.item.id;

      this.$http.patch(uri, this.item)
          .then((response) => {
            this.$toast.success(response.data.message, "Success"),
            this.$router.push({name: 'vendor.offers', query: { page: 'Fees' }})
          }).catch(error => {
        console.log('Error update data: ' + error)
        this.errored = true
      })
    },
    getItem() {

      this.loading = true;
      let uri = '/api/vendor/fees/' + this.$route.params.id + '/edit';
      this.$http.get(uri)
          .then((response) => {
            this.item = response.data.data
          }).catch(error => {
        console.log('Error loading data: ' + error)
        this.errored = true
      }).finally(() =>
        this.mapData(),
        this.loading = false
      );
    },
  }
}
</script>
